#home .carousel-inner .carousel-item::before,
#home .carousel-inner .carousel-item::after{
content: "";
height: 100%;
width: 50%;
position: absolute;
opacity: .7;
}
#home .carousel-inner{
    border-radius: 0 0 30px 30px;
}
#home .carousel-inner .carousel-item::before{
background-color: #202008;
left: 0;
}
#home .carousel-inner .carousel-item::after{
background-color: #c7c9d9;
right: 0;
}
#home .carousel-caption {
    left: 8%;
    width: 45%;
    text-align: left;
    top: 45%;
    bottom: auto;
    transform: translate(-9%, -48%);
}
#home .carousel-caption .description{
    font-size: 20px;
    line-height: 26px;
}
#home .carousel-caption .catalog{
    border-radius: 30px;
    /* background-color: #000; */
    padding: 18px 37px;
}
#home .carousel-caption .explore{
    border-radius: 30px;
    background: none;
    padding: 12px 37px;
}
#home .tags{
    margin-top: 74px;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 42px;
}
#home .tag{
    border: 0.5px solid #fff;
    border-radius: 29.5px;
    color: #fff;
    padding: 10px 21px;
    margin-right: 16px;
    margin-bottom: 16px;
    font-size: 18px;
    /* font-weight: normal; */
    line-height: 27px;
    /* height: auto */
}

.carousel-inner .carousel-item img{
    height: 100vh;
}

.carousel-caption h1{
    font-size: 50px;
    color: #fff;
    font-weight: 700;
}
.carousel-caption a{
    font-weight: 700;
    background-color: var(--primary);
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    padding: 24px 77.2px;
    margin-top: 24px;
    border-radius: 8px;
}
.carousel-caption p{
    font-size: 28px;
    color: #fff;
    font-weight: 400;
    line-height: 40px;
    margin-bottom: 2.5rem;
}
.carousel-control-prev,
.carousel-control-next{
    display: none;
}
#home .carousel-indicators{
    right: 3%;
    left: auto;
    margin: 0;
    width: 44%;
    bottom: 16px;
    background-color: #e7e8ee8a!important;
    border-radius: 12px;
}
#home .carousel-indicators button{
    width: 100% !important;
    border-radius: 0 !important;
    margin: 0;
    padding: 0;
    border: none;
    height: 12px;
    background-color: #e7e8ee8a!important;
}
#home .carousel-indicators button.active{
    border-radius: 12px !important;
    background-color: #fff !important;
}

@media screen and (min-width: 500px) and (max-width: 800px){
    .carousel-caption{
        width: 50%;
    }
    .carousel-caption h1{
        font-size: 40px;
        margin-bottom: 8px;
    }
    .carousel-caption p{
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
    }
}
@media screen and (max-width: 500px){
    .logo{
        width: 55% !important;
        right: -4px !important;
    }
    .carousel-indicators {
        bottom: -18px;
    }
    .carousel-indicators button{
        width: 10px !important;
        height: 10px !important;
        border-radius: 50% !important;
        background-color: #fff !important;
    }
    .carousel-inner .carousel-item img{
        height: 70vh;
    }
    .carousel-caption h1{
        font-size: 18px;
    }
    .carousel-caption p{
        font-size: 15px;
        font-weight: 500;
        line-height: 17.77px;
    }
    .carousel-caption a{
        padding: 12px 16px;
        font-size: 16px;
    }
    #home .carousel-caption .description {
        font-size: 16px;
        line-height: 15px;
        margin-bottom: 1.5rem;
    }
    #home .carousel-caption {
        padding-bottom: 0;
    }
    #home .carousel-caption .catalog {
        padding: 12px;
    }
    #home .tags {
        margin-top: 24px;
        margin-bottom: 15px;
    }
    #home .tag {
        padding: 5px 16px;
        margin-right: 16px;
        margin-bottom: 16px;
        font-size: 15px;
        line-height: 22px;
        text-wrap: nowrap;
    }
}