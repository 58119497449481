
.cursor-pointer{
    cursor: pointer;
}
@media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1400 !important;
    }
}
@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 90% !important;
    }
}
@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 97% !important;
    }
}
@media (max-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 98% !important;
    }
}
.primary{
    color: #2E9596 !important
}
.slick-slide >div{
    height: 100%;
}
.bg-blue{
    background-color: #255073!important;
}