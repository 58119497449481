#highPressur {
    height: 500px;
}
.highPressur::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 25px;
    height: 100%;
    background-image: linear-gradient(to left, rgba(0, 0, 36, .2), rgba(0, 0, 0, 0)), url('../../../assets/table.png');
    /* background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.0), rgba(240, 240, 240, 0.23)), url('../../../assets/table.png'); */
    background-size: cover;
}
.highPressur h1{
    font-size: 64px;
    color: #fff;
    position: absolute;
    width: 40%;
    right: 0;
    top: 35px;
    font-weight: 600;
    text-shadow: 1px 0 #000000, -2px 0 #161414, 0 1.5px #000000, 0 -1.2px #000000;
}
.ce{
    position: absolute;
    bottom: -60px;
    width: 100%;
    left: 0;
}
.ce img{
    width: 168px;
    height: 184px;
    border-radius: 30px;
}
@media screen and (min-width: 500px) and (max-width: 800px){

}
@media screen and (max-width: 500px){
    #highPressur {
        height: 188px;
    }
    .highPressur h1 {
        font-size: 20px;
    }
    .ce {
        bottom: -45px;
    }
    .ce img {
        height: auto;
        width: 90%;
    }
}