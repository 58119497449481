.categories{
  padding: 2rem;
  margin-top: 9rem;
}
/* .categories h1{
  color: var(--primary-color);
  font-weight: 400 !important;
  text-transform: capitalize;
  margin-bottom: 4rem;
  text-align: center;
}

.categories h1::before{
  content: url("../../assets/arrow.png");
  position: absolute;
  left: 77px;
  top: -5px;
  rotate: 20deg;
} */

.categories .cate{
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 0 5px #dedede85;
}
.categories .cate .desc{
  position: absolute;
  background-color: #0e3a5d78;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transform: translate(-50%, -50%);
  padding: 2rem 1.5rem;
  border-radius: 5px;
  text-align: center;
  /* text-wrap: nowrap; */
}
.categories .cate:hover .desc{
  background-color: var(--primary-color);
  transition: .5s ease-in-out;
}

.categories .cate img{
  min-height: 326px !important;
  height: 326px !important;
}
.categories .cate:hover img{
  transform: scale(1.1);
  transition: transform .5s ease-in-out;
}
.categories .desc p{
  font-size: 22px;
  line-height: 1.4;
}
.categories .desc a{
  background: none;
  font-size: 16px;
  text-wrap: nowrap;
}
  /* .categories img {
    max-width: 300px;
    height: 300px;
    border-radius: 10px;
  } */
  .categories a{
    padding: 8px 20px;
    background-color: var(--primary-color);
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 12px;
  }
  @media only screen and (max-width: 500px) {
    .categories {
      padding: 1rem;
      margin-top: 5rem;
    }
    .categories .cate .desc {
      padding: 1rem 8px;
    }
    .categories .desc p {
      font-size: 18px;
      line-height: 1.2;
    }
    .categories .cate img{
      min-height: 200px !important;
      height: 200px !important;
    }
    .categories .cate{
      min-height: 200px !important;
      height: 200px !important;
    }
    .categories .desc a {
      font-size: 14px;
    }
  }