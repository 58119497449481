.PreumiumProducts .viewAll{
    background-color: var(--primary);
    border: none;
    color: #fff;
    padding: 8px 16px;
    border-radius: 15px;
    text-decoration: none;
}
.PreumiumProducts .view{
    background-color: var(--primary);
    border: none;
    color: #fff;
    padding: 6px 21px;
    border-radius: 15px;
    margin-top: 16px;
    text-decoration: none;
    width: fit-content
}
.PreumiumProducts .banner{
    /* background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.318), rgba(240, 240, 240, 0.53)), url('../../assets/header.jpeg'); */
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    padding: 2rem ;
    height: 400px;
    margin-top: 4rem;
    border-radius: 18px;
    position: relative;
}
.PreumiumProducts .banner .row{
    padding-top: 100px;
}
@media screen and (max-width: 500px){
    .PreumiumProducts .slick-track {
        display: flex;
        gap:10px
    }
    .PreumiumProducts .slick-track img {
        width: 100%;
    }
    .PreumiumProducts .cont {
        padding: 1rem 1.5rem!important;
    }
    .PreumiumProducts .card {
        flex-flow: column!important;
        width: 100%!important;
    }

    .PreumiumProducts .banner{
        height: 250px
    }
    .PreumiumProducts .banner .row{
        padding-top: 0
    }
    .PreumiumProducts .banner img{
        width: 50px;
    }
}