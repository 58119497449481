.contact{
    /* background-color: rgb(96,102,107); */
    margin-top: 5rem;
    padding: 50px 0;
    position: relative;
    overflow: hidden;
}
.contact::before{
    content: '';
    position: absolute;
    background-color: var(--secondary);
    width: 96%;
    height: 100%;
    top: 0;
    border-radius: 30px;
}
.contact .card{
    border: none;
    /* o/pacity: 0; */
    animation: fade 1s ease-in;
}
@keyframes fade {
    from {opacity: 0}
  to {opacity: 1}
}
.contact .info{
    border-radius: 8px;
    color: #fff;
    padding: 40px;
    position: relative;
    overflow: hidden;
}

.contact .info h4{
    font-size: 30px;
    font-weight: 700;
    
}
.contact .info p{
    font-size: 18px;
    font-weight: 500;
}

.contact .contacts img{
    margin-left: 8px;
}
.contact .contacts p{
    color: #fff;
    margin-bottom: 50px;
}
.contact .contacts{
    margin-top: 114px;
    margin-bottom: 225px;
}
.contact .form{
    position: relative;
}
.contact .form .back{
    background-color: #efefef;
    padding: 18px
}
.contact .form .back h3{
    color: var(--primary);
    margin-top: 12px;
    margin-bottom: 22px;
}
.contact .form label{
    display: block;
    color:var(--primary)
}
.contact .form input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #ADADAD;
    padding: 8px;
    border-radius: 5px;
    color: #000;
}
.contact .form .submit button{
    background-color: var(--primary);
    padding: 15px 48px 15px 48px;
    border: none;
    color: #fff;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 1rem;
    width: 100%;
}
.contact .form input:focus-visible{
    outline: none;
    border: none;
    border-bottom: 1px solid rgb(96,102,107)
}
@media screen and (min-width: 500px) and (max-width: 800px){

    .contact .info h3 {
        font-size: 20px;
    }
    .contact .info p {
        font-size: 16px;
    }
    .contact .contacts {
        margin-top: 50px;
        margin-bottom: 0;
    }
    .contact .contacts p {
        margin-bottom: 18px;
    }   
    .contact .form {
        padding: 60px 25px 0 10px;
    }
    .contact .mb-5 {
        margin-bottom: 1rem!important;
    }
}
@media screen and (max-width: 500px) {
    .contact{
        padding: 0;
    }
    .contact::before{
        width: 100%;
    }
    .contact .info{
        display: none;
    }
    .contact .form label{
        color: #000;
    }
    .contact .title{
        font-size: 18px;
    }
    .contact .title::before{
        right: 9%
    }
    .contact .title::after{
        left: 9%
    }
    .contact .contacts{
        margin-top: 36px;
        margin-bottom: 90px;
    }
    .contact .contacts p{
        margin-bottom: 18px;
    }
    .contact .form{
        padding: 0 12px;
    }
    .contact .form .mb-5{
        margin-bottom: 1.3rem!important;
    }
}