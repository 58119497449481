.premumProducts{
    margin-top: 8rem;
}
.premumProducts .title{
    font-size: 32px;
}
.premumProducts .view-all{
    border-radius: 30px;
    background-color: var(--primary);
    padding: 13.5px 37px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    margin-top: 1rem;
    display: block;
    width: fit-content;
}
.premumProducts .slick-track{
    display: flex;
    gap: 12px;
}
.premumProducts .slick-slide img{
    border-radius: 22px;
    height: 300px;
}
.premumProducts .slick-slide >div{
    height: 100%;
}
.premumProducts .slick-slide{
    padding: 12px 0;
    height: auto;
}
.premumProducts .slick-next:before,
.premumProducts .slick-prev:before {
    font-size: 24px;
    color: rgb(96,102,107);
}
.premumProducts .slick-slider{
    position: relative;

}
.premumProducts .slick-arrow{
    position: absolute;
    top: 102%;
}
.premumProducts .slick-prev{
    left: 4%;
}
.premumProducts .slick-next{
    left: 7%;
}
@media screen and (max-width: 500px){
    .premumProducts .slick-next {
        left: 15%;
    }
    .premumProducts .slick-slide img{
        height: 75%;
    }
}