.footer{
    background-color: var(--primary);
    padding: 40px 16px 16px;
    margin-top: 116px;
    /* border-radius: 25px 25px 0 0; */
}
.logo{
    justify-content: center;
    color: #fff;
}
.apps,
.social {
    text-align: center;
}
.social{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.social p,
.apps p{
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0;
}
.apps p{
    font-size: 17px;
    display: flex;
}
.apps p i{
    display: block;
    height: max-content;
}
.apps .icons{
    margin-bottom: 16px;
    margin-top: 8px;
    text-align: left;
}
.apps .icons p{
    margin-bottom: 8px;
}
.apps .icons i{
    border: 1px solid;
    border-radius: 8px;
    padding: 4px;
}
.apps a{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    margin-right: 12px;
}
.social .icons img{
    height: 18px;
}
.social .icons{
    margin-top: 18px;
    margin-bottom: 40px;
}
.social .icons a{ 
    margin-left: 16px;
    background: var(--secondary);
    padding: 7px;
    border-radius: 5px;
}
.footer hr{
    color: #fff;
    margin: 8px;
}
.footer .power{
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    line-height: 19.2px;
    margin-top: 18px;
}
.mobile-view{
    display: none;
}
@media screen and (min-width: 500px) and (max-width: 800px){
    .footer {
        margin-top: 60px;
    }
}
.mobile-hidden{
    display: block;
}
.logo-img{
    display: none;
}
@media screen and (max-width: 500px) {
    .mobile-view{
        display: block;
    }
    .logo-title{
        display: none;
    }
    .logo-img{
        background-color: #fff;
        border-radius: 5px;
        display: block;
        margin: 0 auto 1.5rem;
    }
    .mobile-hidden{
        display: none;
    }
    .footer .logo h2{
        text-wrap: nowrap;
    }
    .footer .logo{
        text-align: center;
        margin: 16px auto;
    }
    .footer .social{
        margin-bottom: 16px;
    }
    hr{
        display: none;
    }
    .qr{
        display: none;
    }
    .apps .icons p {
        margin-bottom: 12px;
    }
    .footer .nav-paths a{
        text-wrap: nowrap;
    }
}
.footer .nav-paths{
    list-style: none;
    padding-left: 0;
}
.footer .nav-paths a{
    color: #fff;
    text-decoration: none;
    display: block;
    font-size: 22px;
    font-weight: 500;
}
.footer .nav-paths a:hover{
    color: var(--secondary);
}