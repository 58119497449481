.navbar-nav .nav-link.active{
    color: var(--primary) !important;
    font-weight: 700 !important;
}
.navbar img{
    box-shadow: 8px 8px 14px rgba(0, 0, 0, 0.35);
    background: #fff;
    padding: 5px;
}
.navbar{
    background-color: var(--primary) !important;
    box-shadow: 0.8433734774589539px 12.650602340698242px 19.39759063720703px 0px #E5E5E540;
}
input{
    width: 15rem;
    border-radius: 0;
    border: none;
    padding: 4px 8px;
    background: none;
    color: #fff;
    font-size: 18px;
}
.la-search{
    color: #fff;
    font-size: 20px;
    margin-top: 3px;
    rotate: 270deg;
    margin-bottom: 6px;
}
input::placeholder {
    color: #fff;
    opacity: 1; 
    font-size: 18px;

}
input:focus-visible{
    outline: none;
}
input::-ms-input-placeholder {
    color: var(--primary);
    opacity: 1; /* Firefox */
}
@media (max-width: 500px) {
    .navbar form{
        width: 100%;
    }
}