.collection{
    padding-top: 50px;
    padding-bottom:60px;
    text-align: left;
    background-color: #FDFDFD;
    overflow: hidden;
}
.collection h1{
    font-size: 48px;
}
.collection p{
    font-size: 25px;
    font-weight: normal;
    line-height: 30px;
    color: #515558;
    margin-bottom: 32px;
}
.collection .about{
    border-radius: 30px;
    background-color: var(--primary);
    padding: 16.5px 37px;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    margin-top: 1rem;
    display: block;
    width: fit-content;
}
.collection img{
    border-radius: 35px;
    width: 296px;
    height: 296px;
}
@media screen and (min-width: 500px) and (max-width: 800px){
}
@media screen and (max-width: 500px){
    .collection{
        padding-top: 0;
    }
    .collection h1{
        font-size: 32px;
    }
    .collection p{
        font-size: 18px;
        line-height: 20px;
    }
    .collection .about {
        margin-top: 2rem!important;
        padding: 12.5px 30px;
    }
    .collection img {
        height: auto;
    }
    .collection .p-5 {
        padding: 3rem 2rem !important;
    }
}