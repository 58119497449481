body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Tajawal", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
:root {
  --blue: #5e72e4;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #EE3232;
  --orange: #ff9900;
  --yellow: #FFFA6F;
  --green: #297F00;
  --teal: #20c997;
  --cyan: #3065D0;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #2E9596;
  --secondary: #255073;
  --success: #2BC155;
  --info: #2421DA;
  --warning: #FF6D4D;
  --danger: #FF4847;
  --light: #F4F5F9;
  --dark: #B1B1B1;
  --bg-primary: rgb(96,102,107);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }